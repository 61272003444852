.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content-wrapper {
  border: 2px solid #777;
  width: 400px;
  height: 350px;
  overflow: auto;
  position: relative;
}

.lower-wrap {
  display: flex;
  align-items: center;
  padding-top: 16px;
}

.inpt {
  width: 300px;
  height: 30px;
  border-radius: 8px;
  border: 1px solid #444;
  margin-right: 8px;
}

#btn {
  width: 80px;
  height: 25px;
  border: 1px solid #777;
  border-radius: 8px;
  /* padding: 16px; */
}

.absolute-right {
  position: absolute;
  right: 20px;
}

#btn:hover {
  background-color: #bbb;
  cursor: pointer;
}

#btn:active {
  background-color: #999;
}

.listItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333;
  padding: 8px;
  max-width: 100%;
  min-height: 30px;
  overflow: hidden;
}

.fart-face-79 {
  border: none;
  background-color: white;
  cursor: pointer;
}
.fart-face-79:hover {
  color: #3a5;
}
.fart-face-79:active {
  color: #389;
}

.styled-button {
  cursor: pointer;
  outline: 0;
  text-align: center;
  background-color: transparent;
  border: 1px solid transparent;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  color: #0d6efd;
  height: 26px;
}
.styled-button:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.linkback {
  text-decoration: none;
  color: #0d6efd;
}
.backbutton {
  position: fixed;
  left: 0;
  top: 0;
}
.linkback:hover {
  color: #fff;
}
